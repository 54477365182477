import React from 'react'
import LayoutWithoutNewsletter from '../components/layoutWithoutNewsletter'
import SEO from '../components/seo'
import LiveContainer from '../containers/Live'

type LiveProps = {}

const Live: React.FunctionComponent<LiveProps> = props => {
  return (
    <LayoutWithoutNewsletter>
      <SEO title="Live with tyler" description="Come join me live as I " />
      <LiveContainer />
    </LayoutWithoutNewsletter>
  )
}

export default Live
