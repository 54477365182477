import * as React from 'react'

import {
  LiveTile,
  Description,
  Date,
  TileContainerStyle,
  CalendarButton,
  TileContainerContent,
  TileContainerImg
} from './style'

type TileContainerProps = {
  img: string
  title: string
  description: string
  date?: string
  blog?: string
}

export const TileContainer: React.FunctionComponent<TileContainerProps> = props => {
  return (
    <TileContainerStyle>
      <TileContainerImg>
        <a
          href={props.blog || 'https://www.twitch.tv/livewithtyler'}
          target="_blank"
        >
          <LiveTile src={props.img} alt="hero image for live stream" />
        </a>
      </TileContainerImg>
      <TileContainerContent>
        <h3 style={{ marginBottom: '10px' }}>{props.title}</h3>
        <Description>{props.description}</Description>
        {props.date && <Date>{props.date}</Date>}
        {props.children}
        {!props.blog && (
          <CalendarButton
            href="https://calendar.google.com/calendar/b/3?cid=dHlsZXJjbGFya2RldkBnbWFpbC5jb20"
            target="_blank"
          >
            Add to Google Calendar
          </CalendarButton>
        )}
      </TileContainerContent>
    </TileContainerStyle>
  )
}
