import * as React from 'react'
import SocialProfile from '../../../components/SocialProfile/SocialProfile'
import { IoLogoTwitter } from 'react-icons/io'
import {
  LogoImg,
  Toggle,
  AboutWrapper,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
  StreamH3,
  TileParent
} from './style'
import { TileContainer } from './components/TileContainer'
import { PreviousStreams } from './components/PreviousStreams'
import logo from './logo2.png'
import Chris from './images/chris.jpg'
import Tanner from './images/tanner.jpg'
import Joe from './images/joe.jpg'
import Sara from './images/sara.jpg'
import Maggie from './images/maggie.jpg'
import Houssein from './images/houssein.jpg'
import James from './images/james.jpg'
import Nader from './images/nader.jpg'

const SocialLinks = [
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/iamtylerwclark',
    tooltip: 'Twitter'
  }
]

interface AboutProps {}

const previous = [
  {
    img: James,
    date: 'May 13, 2020 @1:00 MST',
    title: 'Authenticating Next.js with Auth0',
    description:
      "What a time to be a developer! There are so many amazing open source frameworks we can use to create beautiful websites. Next.js is a React framework that provides out-of-the-box server side rendering,  routing, code splitting, customizable plugins, built in CSS support, and much more. Join James and myself as we set up a Next.js app and add authentication with Auth0's SDK",
    blog: 'https://www.youtube.com/watch?v=1fgnWrWJCXI'
  },
  {
    img: Maggie,
    title:
      'Developing visual explanations - A live case study with web workers',
    description: `I'm a visual learner and I believe most people are as well. Sometimes we just need to see something in order to understand it. If you've seen any of the designs put out by maggie appleton, you know what I mean. She has an amazing way of bringing code to life with jaw dropping designs. Maggie will walk us through designing web workers through procreate and an ipad. While at the same time, teach us all about web workers. You don't wanna miss this one! `,
    blog: 'https://www.youtube.com/watch?v=h9uo_48zPxQ'
  },
  {
    img: Joe,
    title: "Let's learn together! An intro to Rust",
    description: `"A language empowering everyone to build reliable and efficient
      software."
      Boasted as a highly performant, easy to learn, and reliable
      language, Rust is something to take a look at. Joe is going to
      help me get up and running with Rust and we'll build something
      live together!`,
    blog: 'https://www.youtube.com/watch?v=Azf2dr-KfEg'
  },
  {
    img: Chris,
    title: 'Animating the web with React Spring',
    description:
      "Animations take a good site and make them great. But *how* do animations actually work? Sprites? Keyframes? Transforms? Or possibly adding an animation is just something that doesn't come with the mock from your UX teammate. Join us as we bring life to an svg with animations from react-spring!",
    blog: 'https://www.youtube.com/watch?v=8bwgH92k1IY'
  },
  {
    img: Sara,
    title: 'Exploring the ends and outs of codesandbox.io',
    description:
      "Codesandbox is revolutionizing online editors. It's ease of use has made it easier than ever to create static sites, full-stack web apps or components, for any device that has access to a web browser. Join sara and I as she teaches me some cool tips and tricks to get the most out of codesandbox. ",
    blog: 'https://www.youtube.com/watch?v=ieHnMazXhYs?start=377'
  },
  {
    img: Tanner,
    title: 'Async data made simple with React Query',
    description:
      "Handling async data is tough. There's pagination, canceling requests, parallel calls, and keeping cached state consistent. Tanner has put together some custom hooks that solve these problems for us. We'll implement these custom hooks in create-react-app live!",
    blog: '/react-query/'
  }
]

const data = [
  {
    img: Houssein,
    date: 'TBD',
    title: 'Optimizing performance in your React app',
    description: `React is great... until you find your app is slow to load. This shouldn't be possible though right? Because React is already performant out of the box. Unfortunately, this is not always true and houssein is going to show us how to fix our problems. `
  },
  {
    img: Nader,
    date: 'May 21st, 2020 @10:30am MST',
    title: 'TBD',
    description: ``
  }
]

const About: React.FunctionComponent<AboutProps> = props => {
  const [view, updateView] = React.useState('Upcoming')

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <LogoImg src={logo} />
        <p style={{ margin: 'auto', maxWidth: '1000px' }}>
          Hey 👋 There are so many things we could all learn from each other. On{' '}
          <a href="https://www.twitch.tv/livewithtyler" target="_blank">
            "Live with Tyler"
          </a>
          , I'll live code with friends from the community about various
          programming topics. My goal is to help everyone learn something new
          that they can use in their life and careers.
        </p>
      </AboutPageTitle>
      <Toggle style={{ margin: '0 0 100px' }}>
        <StreamH3
          style={view === 'Upcoming' ? { borderBottom: 'solid' } : {}}
          onClick={() => updateView('Upcoming')}
        >
          Upcoming schedule{' '}
        </StreamH3>
        <StreamH3
          style={{
            ...(view === 'Previous' && { borderBottom: 'solid' }),
            marginLeft: '35px'
          }}
          onClick={() => updateView('Previous')}
        >
          Previous streams
        </StreamH3>
      </Toggle>
      <TileParent>
        {view === 'Upcoming' ? (
          data.map((item, i) => (
            <TileContainer
              key={i}
              img={item.img}
              date={item.date}
              title={item.title}
              description={item.description}
            />
          ))
        ) : (
          <PreviousStreams data={previous} />
        )}
      </TileParent>
      <AboutDetails>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
