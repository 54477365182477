import * as React from 'react'
import { TileContainer } from '../TileContainer'
import {
  LiveTile,
  Description,
  TileContainerStyle,
  TileContainerContent,
  Debrief,
  Streams
} from './style'

type PreviousStreamsProps = {
  data: Array<{
    img: string
    title: string
    description: string
    blog?: string
  }>
}

export const PreviousStreams: React.FunctionComponent<PreviousStreamsProps> = props => {
  const mostRecent = props.data[0]
  return (
    <div>
      {
        <>
          <h2>Most recent stream</h2>
          <TileContainer
            img={mostRecent.img}
            title={mostRecent.title}
            description={mostRecent.description}
            blog={mostRecent.blog}
          >
            {mostRecent.blog && <Debrief href={mostRecent.blog}>Recap</Debrief>}
          </TileContainer>
        </>
      }
      <Streams>
        {props.data.map((item, i) => {
          if (i === 0) return
          return (
            <TileContainerStyle key={i}>
              <div>
                <a href={item.blog || 'https://www.twitch.tv/livewithtyler'}>
                  <LiveTile src={item.img} alt="hero image for live stream" />
                </a>
              </div>
              <TileContainerContent>
                <h3 style={{ marginBottom: '10px' }}>{item.title}</h3>
                <Description>{item.description}</Description>
                {item.blog && <Debrief href={item.blog}>Recap</Debrief>}
              </TileContainerContent>
            </TileContainerStyle>
          )
        })}
      </Streams>
    </div>
  )
}
